/* Component Dependencies */
var accuWeatherTemplate = require('templates/accuWeather.hbs');
var AriesComponent = require('libs/aries-component');
var $ = require('jquery');
var accuWeatherInfoURL = "/aries-hsia/v1/accuWeatherInfo";

AriesComponent.create({
  type: 'accuWeather',
  template: {
    'accuWeather': accuWeatherTemplate
  },
  bindEvents: function($) {
    var _self = this;

    $(window).on('load', function() {
        _self.handleAccuWeatherInformationDisplay();
    });
  },
  handleAccuWeatherInformationDisplay: function() {
    function getCookie(name) {
      function escape(s) {
          return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1');
      }
      var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
      return match ? match[1] : null;
    }
    var _self = this;
    var weatherIcons = {1:"t-icon-accuweather-sunny", 2:"t-icon-accuweather-mostly-sunny", 3:"t-icon-accuweather-partly-sunny",
                        4:"t-icon-accuweather-intermittent-clouds-2", 5:"t-icon-accuweather-hazy-sunshine", 6:"t-icon-accuweather-mostly-cloudy-2",
                        7:"t-icon-accuweather-cloudy", 8:"t-icon-accuweather-dreary", 
                        11:"t-icon-accuweather-fog", 12:"t-icon-accuweather-showers",
                        13:"t-icon-accuweather-mostly-cloudy-showers", 14:"t-icon-accuweather-partly-sunny-showers", 15:"t-icon-accuweather-thunderstorms",
                        16:"t-icon-accuweather-mostly-cloudy-thunder",18:"t-icon-accuweather-rain",
                        19:"t-icon-accuweather-flurries", 20:"t-icon-accuweather-mostly-cloudy-flurries", 21:"t-icon-accuweather-partly-sunny-flurries",
                        22:"t-icon-accuweather-snow", 23:"t-icon-accuweather-mostly-cloudy-snow", 24:"t-icon-accuweather-ice",
                        25:"t-icon-accuweather-sleet", 26:"t-icon-accuweather-freezing-rain", 
                        29:"t-icon-accuweather-rain-snow", 30:"t-icon-accuweather-hot",
                        31:"t-icon-accuweather-cold", 32:"t-icon-accuweather-windy", 33:"t-icon-accuweather-clear",
                        34:"t-icon-accuweather-mostly-clear", 35:"t-icon-accuweather-partly-cloudy", 36:"t-icon-accuweather-intermittent-clouds",
                        37:"t-icon-accuweather-hazy-moonlight", 38:"t-icon-accuweather-mostly-cloudy", 39:"t-icon-accuweather-partly-cloudy-showers",
                        40:"t-icon-accuweather-mostly-cloudy-showers2", 41:"t-icon-accuweather-partly-cloudy-thunder", 42:"t-icon-accuweather-mostly-cloudy-thunder",
                        43:"t-icon-accuweather-mostly-cloudy-flurries-2", 44:"t-icon-accuweather-mostly-cloudy-snow-2"}
    if(typeof window.getCookie === "function"){
      var sessionToken = window.getCookie('sessionID');
    }else {
      var sessionToken = getCookie('sessionID');
    }
    var noOfDays = this.$el[0].getAttribute("data-days");
    var dataRequired = this.$el[0].getAttribute("data-required");
    if(dataRequired.toLowerCase() === "false"){
      dataRequired = "true"
    }
    var $accuWeatherHeaderNav = this.$el[0].closest('.l-navbar-container');
    var $accuWeatherContainer = this.$el[0];
    accuWeatherDiv = '';
    accuWeatherDivAppended = ''; 
    context = {
      "context": {
           "noOfDays":noOfDays,
           "dataRequired": dataRequired
      },
      "sessionToken": sessionToken,
      "variation": "0.1"  
    }
    _self.makeAjaxCall({
      url: accuWeatherInfoURL,
      contentType: 'application/json',
      method: 'POST',
      data: JSON.stringify(context),
      success : function(data){
        var response = data.component.data;
        if(response.noOfDays === "1" && $accuWeatherHeaderNav){
           $accuWeatherContainer.innerHTML = '<div class="accu-weather-header">' +
            '<a href="'+response?.link+'" target="_blank" class="accu-weather-temperature accu-weather-desktop-link"><div class="accu-weather-icon '+ weatherIcons[response?.accuWeatherData[0]?.weatherIcon]+'"></div><span class="t-font-l">'+response?.accuWeatherData[0]?.temperature+'</span>' +
            '<span class="t-font-l">&#176</span><span class="t-font-s  t-font-weight-semibold">'+response?.temperatureUnit+'</span></a>' +
            '<a href="'+response?.mobileLink+'" target="_blank" class="accu-weather-temperature accu-weather-mobile-link"><div class="accu-weather-icon '+ weatherIcons[response?.accuWeatherData[0]?.weatherIcon]+'"></div><span class="t-font-l">'+response?.accuWeatherData[0]?.temperature+'</span>' +
            '<span class="t-font-l">&#176</span><span class="t-font-s  t-font-weight-semibold">'+response?.temperatureUnit+'</span></a>' +
            '<a href="'+response?.link+'" target="_blank" class="accu-weather-logo"><img class="accu-weather-logo-img" src="/aries/standards/assets/images/accuweather-logo.png" alt="AccuWeather logo"/><div class="accu-weather-desktop-link"><span class="t-icon-arrow-right arrow-icon"></span></div></a>' +
            '<div class="accu-weather-mobile-link"><a href="'+response?.mobileLink+'" class="t-icon-arrow-right arrow-icon" target="_blank"></a></div></div>'
            var accuWeatherDiv = document.querySelector('.accu-weather-header');
            var navBar = document.querySelector('#hsia-navbar-collapse');
            var headerSection = document.querySelector('.l-header-section.l-clear.l-display-flex.l-reserve-now');
            if(accuWeatherDiv && navBar){
                if(headerSection){
                  accuWeatherDiv.style.right = -(((headerSection.getBoundingClientRect().width)-(navBar.getBoundingClientRect().width))/2)+"px";
                } else {
                  accuWeatherDiv.style.right = 0;
                }
                navBar.style.overflowX = "visible";
            }
          }else {
            accuWeatherDiv = '<div class="accu-weather-component accu-weather-component-desktop"><div class="accu-weather-title t-font-s t-font-weight-semibold">'+response.labels.forecast+'</div><a href="'+response?.link+'" target="_blank" class="accu-weather-info-alignment">'
            response.accuWeatherData.forEach(function(data, index, array){
              accuWeatherDiv += '<div class="accu-weather-info"><span class="t-font-s accu-weather-title t-font-weight-semibold">'+response?.labels?.days[data.day - 1]?.daylabel+'</span>'+
              '<span class="t-font-l accu-weather-icon '+  weatherIcons[data.weatherIcon]+'"></span><span class="t-font-l t-font-weight-semibold">'+data.temperature+'</span>'+
              '<span class="t-font-s t-font-weight-semibold">&#176</span><span class="t-font-s t-font-weight-semibold">'+response?.temperatureUnit+'</span></div>'
              if(index < array.length - 1){
                accuWeatherDiv += '<div class="seperator"></div>'
              }
            })
            accuWeatherDiv += '</a><a href="'+response?.link+'" target="_blank" class="accu-weather-info-alignment"><img class="accu-weather-logo-img" src="/aries/standards/assets/images/accuweather-logo.png" alt="AccuWeather logo"/>'+
            '<span class="t-icon-arrow-right arrow-icon" ></span></a></div>'

            accuWeatherDiv += '<div class="accu-weather-component accu-weather-component-mobile"><div class="accu-weather-title t-font-s t-font-weight-semibold">'+response?.labels?.forecast+'</div>'+
            '<a href="'+response?.mobileLink+'" target="_blank" class="accu-weather-info-alignment"><img class="accu-weather-logo-img" src="/aries/standards/assets/images/accuweather-logo.png" alt="AccuWeather logo"/>'+
            '<span class="t-icon-arrow-right arrow-icon"></span></a></div><div class="accu-weather-mobile-wrapper"><a href="'+response?.mobileLink+'" target="_blank" class="accu-weather-component accu-weather-component-mobile accu-weather-info-alignment accu-weather-info-alignment-mobile ">'

            response?.accuWeatherData?.forEach(function(data, index, array){
              if(index <3){
                accuWeatherDiv += '<div class="">'+
              '<div class="accu-weather-info"><div><span class="t-font-l accu-weather-icon '+  weatherIcons[data.weatherIcon]+'"></span></div><div class="accu-weather-info-align-start">'+
              '<div class="t-font-s accu-weather-title accu-weather-info-text t-font-weight-semibold">'+response?.labels?.days[data.day - 1]?.daylabel+'</div>'+
              '<div class="accu-weather-info-align-baseline">'+
              '<span class="t-font-l t-font-weight-semibold">'+data.temperature+'</span>'+
              '<span class="t-font-s t-font-weight-semibold">&#176</span><span class="t-font-s t-font-weight-semibold">'+response?.temperatureUnit+'</span>'
                if(index <2){
                  accuWeatherDiv += '<div class="seperator"></div>'
                }
                accuWeatherDiv += '</div></div></div></div>'
              }
              
            })
            accuWeatherDiv += '</a></div>'
            accuWeatherDivAppended += accuWeatherDiv;
            $accuWeatherContainer.innerHTML = accuWeatherDiv;

          }
      }
    });
  }
});
